import React from "react";
import { PageLayout, ContactForm } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class JobsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout className={"jobs-page"} hero={null}>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={"12"}>
              <Header as={"h1"}>Work With Us</Header>
              <Header textAlign={"center"} as={"h4"}>
                We’d love to have you join our team! Please use the form below
                to apply. Feel free to send in your resume even if you don't see
                the position you're looking for listed.
              </Header>
            </Grid.Column>
          </Grid>
          <Grid stackable={true} centered={true}>
            <Grid.Column width={14}>
              <Grid
                stackable={true}
                columns={3}
                className={"jobs-info-container"}
                centered={true}
                textAlign={"center"}
              >
                <Grid.Column textAlign={"center"}>
                  <div>
                    <h4>Cashier</h4>
                    <p>
                      Weeyums Philly Style is Greater Atlanta’s premier philly
                      cheesesteak restaurant that has been in the Stone Mountain
                      Village for over 20 years. We closed during the pandemic
                      to complete much needed renovations to meet the COVID19
                      standards and a safer environment for our customers. We
                      are excited to be re-opening and looking for stellar
                      customer service candidates.
                    </p>
                    <p>
                      We are looking for high energy, experienced cashiers with
                      previous customer service experience in a restaurant
                      setting preferably
                    </p>
                    <p>Desired skills:</p>
                    <p>- Previous cashier experience</p>
                    <p>- Previous customer service experience</p>
                    <p>- Food service experience</p>
                    <p>- Prior payment handling experience preferred</p>
                    <p>Essential Duties and Responsibilities:</p>
                    <p>
                      - Serves customers in a friendly, efficient manner
                      following outlined steps of service.
                    </p>
                    <p>- Takes and fill orders</p>
                    <p>
                      - Maintaining accuracy of food orders before providing to
                      customer
                    </p>
                    <p>
                      - Monitoring the status of placed orders and encouraging
                      the workers for faster preparation of orders
                    </p>
                    <p>
                      - Must be knowledgeable in basic arithmetic and skilled in
                      cash counting and handling
                    </p>
                    <p>- Computing orders via POS System</p>
                    <p>- Assists supervisor or manager with inventory.</p>
                    <p>
                      - Performs clean-up duties in the stand to maintain the
                      quality appearance of the work area, including cleaning
                      equipment.
                    </p>
                    <p>
                      - Resolves customer concerns and relay relevant
                      information to supervisor.
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column textAlign={"center"}>
                  <div>
                    <h4>Cook</h4>
                    <p>Reports to: Lead Cook</p>
                    <p>Supervises: N/A</p>
                    <p>
                      Position Summary: Cook will be responsible for the
                      preparation, distribution, handling and storage of all
                      food products per SOP (Standard Operating Procedure).
                    </p>
                    <p>Minimum Qualifications:</p>
                    <p>- High School Diploma or Equivalent</p>
                    <p>
                      - Minimum 2-3 year experience working in a fast paced
                      restaurant
                    </p>
                    <p>- Certified ServSafe Food Handler</p>
                    <p>Essential Duties and Responsibilities:</p>
                    <p>
                      - Performs prep work such as washing, peeling, cutting and
                      seeding fruits and vegetables. Weighs and measures
                      designated ingredients.
                    </p>
                    <p>
                      - Carries pans, kettles and trays of food to and from work
                      stations, stove and refrigerator in accordance with safety
                      standards.
                    </p>
                    <p>
                      - Stores food in designated areas following wrapping,
                      dating, food safety and rotation procedures.
                    </p>
                    <p>- Cleans work areas, equipment and utensils.</p>
                    <p>
                      - Distributes supplies, utensils and portable equipment.
                    </p>
                    <p>
                      - Utilizes approved food recipes and production standards
                      to ensure proper quality, serving temperatures and
                      standard portion control.
                    </p>
                    <p>
                      - Serves customers in a friendly, efficient manner
                      following outlined steps of service.
                    </p>
                    <p>
                      - Resolves customer concerns and relay relevant
                      information to supervisor.
                    </p>
                    <p>
                      - Ensures compliance with company service standards and
                      inventory and cash control procedures.
                    </p>
                    <p>
                      - Assures compliance with all sanitation and safety
                      requirements.
                    </p>
                    <p>- Performs other duties as assigned.</p>
                  </div>
                </Grid.Column>
                <Grid.Column textAlign={"center"}>
                  <div>
                    <h4>Food Handler/Runner</h4>
                    <p>Supervises: NA</p>
                    <p>
                      Position Summary: Assembles customer orders (ensuring
                      accuracy), quality control, and delivers orders to
                      customers in lobby.
                    </p>
                    <p>Minimum Qualifications:</p>
                    <p>- High School Diploma or Equivalent</p>
                    <p>- High School Diploma or Equivalent</p>
                    <p>- Great Customer Service</p>
                    <p>- Essential Duties and Responsibilities:</p>
                    <p>
                      - Greet, assist and thank guests in a friendly, efficient
                      and timely manner
                    </p>
                    <p>
                      - Serves food and beverage items in correct proportions,
                      following proper sanitation guidelines.
                    </p>
                    <p>Records all waste</p>
                    <p>
                      Participates in set-up of stand, arranges inventory, and
                      notifies Supervisor of any inventory deficiency or
                      shortages.
                    </p>
                    <p>
                      Performs clean-up duties in the stand to maintain the
                      quality appearance of the work area, including cleaning
                      equipment.
                    </p>
                    <p>Performs other duties as assigned by Manager.</p>
                    <p>
                      Properly records all transactions via the point of sale
                      system.
                    </p>
                    <p>Processes credit card if necessary.</p>
                    <p>
                      Shows flexibility by rotating to other stands when
                      necessary.
                    </p>
                    <p>Assists supervisor or manager with inventory.</p>
                    <p>
                      When manager or supervisor is not present, makes sure the
                      shift is running smoothly.
                    </p>
                    <p>Physical Requirements:</p>
                    <p>
                      Constant standing, walking, bending, reaching and
                      repetitive motions
                    </p>
                    <p>
                      Ability to remain stationary for entire length of shift
                    </p>
                    <p>
                      Ability to perform normal lifting of stock up to 50
                      pounds.
                    </p>
                  </div>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column width={"10"}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  phone,
                  coverLetter,
                  position,
                }) =>
                  `Someone has reached out to you via your website Jobs form: Name: ${name}, Email: ${email}, Phone: ${phone}, Position: ${position}, Cover Letter: ${coverLetter},`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "position",
                    displayLabel: "Position",
                    type: "select",
                    required: false,
                    options: [
                      { label: "Cashier", value: "Cashier" },
                      { label: "Cook", value: "Cook" },
                      {
                        label: "Food Handler/Runner",
                        value: "Food Handler/Runner",
                      },
                    ],
                  },
                  {
                    label: "coverLetter",
                    displayLabel: "Cover Letter",
                    placeholder: "Cover Letter",
                    type: "textarea",
                    required: true,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      primaryLocation {
        _id
      }
      _id
      type
    }
  }
`;
